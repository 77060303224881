<template>
  <div class="container page_get_question_config">
    <van-nav-bar
      title="筛选"
      left-arrow
      class="nav_bar"
      @click-left="nav_back"
    />

    <div class="config_box">
      <div class="config_list">
        <span>收藏</span>
        <van-switch
          v-model="is_favorited"
          class="switch"
          size="28"
          active-color="#FF5859"
          inactive-color="#CED0D6"
        />
        <div class="clr"></div>
      </div>

      <div class="config_list">
        <span>未答</span>
        <van-switch
          v-model="is_unanswered"
          class="switch"
          size="28"
          active-color="#FF5859"
          inactive-color="#CED0D6"
        />
        <div class="clr"></div>
      </div>

      <div class="config_list">
        <span>答对</span>
        <van-switch
          v-model="is_right"
          class="switch"
          size="28"
          active-color="#FF5859"
          inactive-color="#CED0D6"
        />
        <div class="clr"></div>
      </div>

      <div class="config_list">
        <span>答错</span>
        <van-switch
          v-model="is_wrong"
          class="switch"
          size="28"
          active-color="#FF5859"
          inactive-color="#CED0D6"
        />
        <div class="clr"></div>
      </div>

      <img
        src="~@/assets/img/practice/config_submit.png"
        class="config_submit"
        @click="submit_config()"
      />
    </div>
  </div>
</template>
<script>
import { NavBar, Switch, Toast } from "vant";
// import { get_set_questions } from "@/api/practice/api";
import Cookies from "js-cookie";
import { get_filter_item, int_to_boolean ,boolean_to_int } from "@/utils/common";
export default {
  name: "GetQuestionConfig",
  data() {
    let is_unanswered = int_to_boolean(get_filter_item("is_unanswered"));
    let is_right = int_to_boolean(get_filter_item("is_right"));
    let is_favorited = int_to_boolean(get_filter_item("is_favorited"));
    let is_wrong = int_to_boolean(get_filter_item("is_wrong"));
    return {
      is_unanswered: is_unanswered,
      is_right: is_right,
      is_favorited: is_favorited,
      is_wrong: is_wrong
    };
  },
  watch: {
    is_unanswered() {
      this.data_change("is_unanswered");
    },
    is_right() {
      this.data_change("is_right");
    },
    is_favorited() {
      this.data_change("is_favorited");
    },
    is_wrong() {
      this.data_change("is_wrong");
    }
  },
  methods: {
    nav_back() {
      this.$router.push({ path: "/question_card?ts=" + Math.random() });
    },
    data_change: function(item) {
      console.log(item);
      //检测至少有一项选中
      if (
        this.is_unanswered == false &&
        this.is_right == false &&
        this.is_favorited == false &&
        this.is_wrong == false
      ) {
        Toast("至少选择一项");
        this[item] = !this[item];
        return false;
      }

      return true;
    },
    //提交配置
    submit_config(){
      Cookies.set("is_unanswered", boolean_to_int(this.is_unanswered));
      Cookies.set("is_right", boolean_to_int(this.is_right));
      Cookies.set("is_favorited", boolean_to_int(this.is_favorited));
      Cookies.set("is_wrong", boolean_to_int(this.is_wrong));
      Cookies.set("is_get_new_set", 1);
      this.nav_back()
    }
  },
  components: {
    [NavBar.name]: NavBar,
    [Switch.name]: Switch,
    [Toast.name]: Toast
  }
};
</script>
<style lang="stylus">
.page_get_question_config .nav_bar{
    background #fff
    border-bottom 0
}
.page_get_question_config .van-nav-bar__content{
    height: 54px;
}
.page_get_question_config .van-nav-bar__title{
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000;
}
.page_get_question_config .van-hairline--bottom::after{
    border none
}
.page_get_question_config .van-nav-bar .van-icon{
    color #000
    font-size 18px
}
.page_get_question_config .van-nav-bar .van-icon{
    font-size:22px !important
}
</style>

<style lang="stylus" scoped>
.container{
    width 100%
    height 100%
    background #fff
    position relative
}
.config_box{
    width 90%
    margin 0 auto
    margin-top 26px
}
    .config_list{
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #222;
        line-height: 22px;
        border-bottom: 1px solid #edeef1;
        padding: 13px 0;
    }
        span{
            position relative
            top 3px
        }
        .switch{
            float right
        }
.config_submit{
  width 90%
  margin 0 auto
  margin-top 60px
  display block
}
</style>
